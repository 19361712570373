import React from 'react'
import styles from './/ResultsOverview.module.css';

export default function ResultsOverview(props) {
    return (
        <table className={ styles.resultsTable }>
            <thead>
            <tr key={ 'header' } >
                <th>Event</th> 
                <th>Class</th> 
                <th>Driver</th>
                <th>Vehicle</th>  
                <th>Run 1</th>
                <th>Run 2</th>
                <th>Run 3</th>  
                <th>Run 4</th>  
                <th>Run 5</th>    
            </tr>
            </thead>
            <tbody>
            {props.data && props.data
                .map((row, rowIndex) => {
                    return (
                        <tr key={ 'row' + rowIndex } >
                            <td>{ row.event.name }</td>
                            <td>{ row.class.abbreviation }</td>
                            <td>{ row.driver.first_name +' ' + row.driver.last_name }</td>
                            <td>#{ row.vehicle_number + " " + row.vehicle.make +' ' +row.vehicle.model}</td>
                            {row.runs && row.runs
                                .map((run, cellIndex) => {
                                return (
                                    <td key={ 'head' + cellIndex } className={ styles.timeCell }>
                                        { !row.dnf && run.time }
                                        { !row.dnf && run.penalties > 0 && ( <> +{ run.penalties } </> )}
                                        { row.dnf && (<>DNF</>) }
                                    </td> 
                                );
                            })}   
                        </tr>
                    );
            })}
            </tbody>
        </table>
    )
}