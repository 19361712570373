import Admin from "./Pages/Admin/admin";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Classes from "./Pages/Admin/classes";
import Clubs from "./Pages/Admin/clubs";
import Drivers from "./Pages/Admin/drivers";
import Events from "./Pages/Admin/events";
import Footer from "./Components/Footer/Footer";
import Home from "./Pages/home";
import Header from "./Components/Header/Header";
import Results from "./Pages/Admin/results";
import Series from "./Pages/Admin/series";
import Vehicles from "./Pages/Admin/vehicles";

import "./Styles/Globals.css";

function App() {
  return (
    <div id="app"> 
      <BrowserRouter>
        <Header />
        <div id="main">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/admin" element={<Admin />} />
            <Route exact path="/admin/classes" element={<Classes />} />
            <Route exact path="/admin/clubs" element={<Clubs />} />
            <Route exact path="/admin/drivers" element={<Drivers />} />
            <Route exact path="/admin/series" element={<Series />} />
            <Route exact path="/admin/events" element={<Events />} />
            <Route exact path="/admin/results" element={<Results />} />
            <Route exact path="/admin/vehicles" element={<Vehicles />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
