import GridTable from "../../Components/GridTable/GridTable";
import useFetch from '../../Services/useFetch';
import AdminLayout from "../../Components/Layouts/AdminLayout/AdminLayout";

export default function Drivers() {

    const { data, isPending, error } = useFetch(process.env.REACT_APP_API_URL+'api/driver');

    const layout = [
        { label: 'First Name', prop: 'first_name' },
        { label: 'Last Name', prop: 'last_name' }
    ]

    return (
        <AdminLayout 
            title='Drivers'
            description='Drivers competing in autocross'
        >
            { isPending && <div>Loading...</div> }
            { error && <div>{ error }</div> }
            { data && <GridTable def={ layout } data={ data } api='driver' /> }
        </AdminLayout>
    )
}