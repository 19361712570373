import GridTable from "../../Components/GridTable/GridTable";
import useFetch from '../../Services/useFetch';
import AdminLayout from "../../Components/Layouts/AdminLayout/AdminLayout";

export default function Seriess() {

    const { data, isPending, error } = useFetch(process.env.REACT_APP_API_URL+'api/series');

    const layout = [
        { label: 'Series Name', prop: 'name' },
        { label: 'Year', prop: 'year' },
        { label: 'Club', prop: 'club.name' }
    ]

    return (
        <AdminLayout 
            title='Series'
            description='Series offered by clubs'
        >
            { isPending && <div>Loading...</div> }
            { error && <div>{ error }</div> }
            { data && <GridTable def={ layout } data={ data } api='series' /> }
        </AdminLayout>
    )
}