
import AdminLayout from "../../Components/Layouts/AdminLayout/AdminLayout";

export default function Admin() {

    return (
        <AdminLayout 
            title='Home'
            description='Administration section for editing data.'
        >
        </AdminLayout>
    )
}