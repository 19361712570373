import { Link } from "react-router-dom";
import styles from './AdminLayout.module.css';

const AdminLayout = (props) => {
    return (
        <>
        <div className={ styles.adminNavWrapper }>
            <h1>Admin - { props.title }</h1>
            <nav className={ styles.adminNav }>
                <Link className={`${ props.title ==='Clubs' ? styles.selected : ""}`} to="/admin/clubs">Clubs</Link>
                <Link className={`${ props.title ==='Series' ? styles.selected : ""}`} to="/admin/series">Series</Link>
                <Link className={`${ props.title ==='Events' ? styles.selected : ""}`} to="/admin/events">Events</Link>
                <Link className={`${ props.title ==='Results' ? styles.selected : ""}`} to="/admin/results">Results</Link>
                <Link className={`${ props.title ==='Drivers' ? styles.selected : ""}`} to="/admin/drivers">Drivers</Link>
                <Link className={`${ props.title ==='Classes' ? styles.selected : ""}`} to="/admin/classes">Classes</Link>
                <Link className={`${ props.title ==='Vehicles' ? styles.selected : ""}`} to="/admin/vehicles">Vehicles</Link>
            </nav>
        </div>
        <div className={ styles.adminContent }>{ props.children }</div>
        </>
    );
}
 
export default AdminLayout;