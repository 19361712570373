import React from 'react'
import PageLayout from '../Components/Layouts/PageLayout/PageLayout'

export default function Home() {
    return (
        <PageLayout
            title='Home'
            description='This is the homepage'
        ></PageLayout>
    )
}