import React from 'react'
import styles from './/GridTable.module.css';
import { formatSelector } from '../../Utils/StringFormatter';


export default function GridTable(props) {

    const createRowClickHandler = (event) => {
        event.preventDefault();
        
        // Create formData from form
        const form = document.getElementById('createRowForm');
        const formData = new FormData(form);

       // convert formData to JavaScript Object
       const jsObj = Object.fromEntries(formData.entries());

        fetch(process.env.REACT_APP_API_URL+'api/' + props.api,{
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(jsObj)
        })
        .then(res => {
         
             if(!res.ok) {
                 throw Error('could not fetch the data');
             }

            // Change the button to Text
            event.target.innerHTML="Saved";
            // Disable the button
            event.target.disabled = true;
            
             return res.json();
        })
        .catch((err) => {
            console.log(err.message);
         
        })  
    }


    const deleteRowClickHandler = (event, rowId) => {
        event.preventDefault();
        
        const apiPath = process.env.REACT_APP_API_URL + "api/" + props.api + "/" + rowId;

        fetch(apiPath,{
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'DELETE'
        })
        .then(res => {
         
             if(!res.ok) {
                 throw Error('could not fetch the data');
             }

            // Change the button to Text
            event.target.innerHTML="Deleted";
            // Disable the button
            event.target.disabled = true;
            
             return res.json();
        })
        .catch((err) => {
            console.log(err.message);
         
        })  
    }

    return (
        <form id='createRowForm'>
            <table className = { styles.gridTable }>

                <thead >
                    <tr>
                    {props.def && props.def
                        .map((head, headIndex) => {
                        return (
                            <th key={ 'heading' + headIndex }>{ head.label }</th> 
                        );
                    })}
                    <th></th>
                    </tr>
                </thead>
                <tbody>
                {props.data && props.data
                    .map((row, rowIndex) => {
                    return (
                        <tr key={ 'row' + rowIndex }>
                            {props.def && props.def
                                .map((head, cellIndex) => {

                                let cellContent = head.prop.split('.').reduce((a, b) => a[b], row);
                                
                                // Check if string formatter is required
                                if(props.def[cellIndex].format) {
                                    cellContent = formatSelector(props.def[cellIndex].format, cellContent);
                                }

                                return (
                                    <td key={ 'head' + cellIndex } className={`${ props.def[cellIndex].align ==='right' ? styles.rightAligned : ""}`}>
                                        { cellContent }
                                    </td> 
                                );
                            })}
                            <td><button onClick={ (e)=> deleteRowClickHandler(e, row._id) }>Delete</button></td>
                        </tr>
                    );
                })}
                <tr>
                    
                    {props.def && props.def
                        .map((head, cellIndex) => {
                        return (
                            <td key={ 'edit' + cellIndex } className={`${ props.def[cellIndex].align ==='right' ? styles.rightAligned : ""}`}>
                                <input name={head.prop.split('.').reduce((a, b) => a[b])} type="text"></input>
                            </td> 
                        );
                    })}
                    <td><button onClick={ (e)=> createRowClickHandler(e) }>Create</button></td>
                    
                </tr>
                </tbody>
            </table>
        </form>
    )
}