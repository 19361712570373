import ResultsOverview from "../../Components/ResultsOverview/ResultsOverview";
import useFetch from '../../Services/useFetch';
import AdminLayout from "../../Components/Layouts/AdminLayout/AdminLayout";

export default function Results() {

    const { data, isPending, error } = useFetch(process.env.REACT_APP_API_URL+'api/result');

    return (
        <AdminLayout 
            title='Results'
            description='Results from an event'
        >
            { isPending && <div>Loading...</div> }
            { error && <div>{ error }</div> }
            { data && <ResultsOverview  data={ data } api='result' /> }
        </AdminLayout>
    )
}