import GridTable from "../../Components/GridTable/GridTable";
import useFetch from '../../Services/useFetch';
import AdminLayout from "../../Components/Layouts/AdminLayout/AdminLayout";

export default function Vehicles() {

    const { data, isPending, error } = useFetch(process.env.REACT_APP_API_URL+'api/vehicle');

    const layout = [
        { label: 'Year', prop: 'year' },
        { label: 'Make', prop: 'make' },
        { label: 'Model', prop: 'model' },
        { label: 'Color', prop: 'color'}
    ]

    return (
        <AdminLayout 
            title='Vehicles'
            description='Autocross racecars'
        >
            { isPending && <div>Loading...</div> }
            { error && <div>{ error }</div> }
            { data && <GridTable def={ layout } data={ data } api='vehicle' /> }
        </AdminLayout>
    )
}