export function formatSelector(format, str) {
    if(format ==='day') {
        return formatDay(str);
    }
    if(format === 'url') {
        return formatURL(str);
    }
}

export function formatDay(str) {
    let monthArray = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    let strDate = new Date(str);
    let year = strDate.getFullYear();
    let monthIndex = strDate.getMonth();
    let date = strDate.getDate();
    if(date < 10) {
        date = "0" + date;
    }
    return date + " " + monthArray[monthIndex] + " " + year;
}

export function formatURL(str) {
    return <a href={ str } target="_blank" rel="noopener noreferrer">{ str }</a>
}