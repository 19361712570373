import GridTable from "../../Components/GridTable/GridTable";
import useFetch from '../../Services/useFetch';
import AdminLayout from "../../Components/Layouts/AdminLayout/AdminLayout";

export default function Events() {

    const { data, isPending, error } = useFetch(process.env.REACT_APP_API_URL+'api/event');

    const layout = [
        { label: 'Series Name', prop: 'series.name' },
        { label: 'Round', prop: 'round', align: 'right' },
        { label: 'Name', prop: 'name' },
        { label: 'Short Name', prop: 'short_name' },
        { label: 'Date', prop: 'date', align:'right', format: 'day' },
        { label: 'City', prop: 'city' },
        { label: 'Location', prop: 'location' }
    ]

    return (
        <AdminLayout 
            title='Events'
            description='Events making up a series'
        >
            { isPending && <div>Loading...</div> }
            { error && <div>{ error }</div> }
            { data && <GridTable def={ layout } data={ data } api='event' /> }
        </AdminLayout>
    )
}