import GridTable from "../../Components/GridTable/GridTable";
import useFetch from '../../Services/useFetch';
import AdminLayout from "../../Components/Layouts/AdminLayout/AdminLayout";

export default function Clubs() {

    const init = useFetch(process.env.REACT_APP_API_URL+'api/club');

    const layout = [
        { label: 'Club Name', prop: 'name' },
        { label: 'Website', prop: 'website', format: 'url' }
    ]

    return (
        <AdminLayout 
            title='Clubs'
            description='Autocross clubs'
        >
            { init.isPending && <div>Loading...</div> }
            { init.error && <div>{ init.error }</div> }
            { init.data && <GridTable def={ layout } data={ init.data } api='club' /> }
        </AdminLayout>
    )
}